import React from "react"
import { JSONLD, Generic } from "react-structured-data"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"
config.autoAddCss = false

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Main from "../components/main/main"

const IndexPage = () => (
  <Layout className="main inner-page-with-banner">
    <SEO
      title={`Used Lab Equipment For Sale: Buy Online & Auctions`}
      description={`Surplus Solutions is the leader in laboratory and biotech equipment sales, brokerage, consignment and auctions. Register for an online auction or shop used lab equipment today!`}
    />
    <JSONLD>
      <Generic
        type="review"
        jsonldtype="LocalBusiness"
        schema={{
          "@id": "https://ssllc.com",
          name: "Surplus Solutions, LLC",
          url: "https://ssllc.com",
          telephone: "+14015260055",
          image:
            "https://ssllc.com/static/Boxtruck-75bd5fa08637ddc39220ed4234fb05da.jpg",
          sameAs: [
            "https://www.linkedin.com/company/surplus-solutions-llc/",
            "https://www.facebook.com/surplussolutionsllc",
            "https://twitter.com/SurplusSolution",
            "https://www.youtube.com/channel/UCJPZsn4qLfwHMwB8vVKPUYg",
          ],
        }}
      >
        <Generic
          type="address"
          jsonldtype="PostalAddress"
          schema={{
            streetAddress: "2010 Diamond Hill Road",
            addressLocality: "Woonsocket",
            addressRegion: "RI",
            postalCode: "02895",
            addressCountry: "US",
          }}
        />
      </Generic>
    </JSONLD>
    <Main />
  </Layout>
)

export default IndexPage
