import React, { useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";

import DEFAULT_SLIDE from "../../images/content/home-slider/default-slide.jpg"

const HomeSlider = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarketing(
        filter: {
          active: { eq: "Active" }
          typeName: { eq: "Home Page Carousel" }
        }
        sort: { fields: sortOrder, order: ASC }
      ) {
        totalCount
        nodes {
          buttonText
          buttonUrl
          name
          messageBoxDetails
          typeName
          backgroundImage
          sortOrder
          textBoxVisible
        }
      }
    }
  `)

  const sliderSettings = {autoplay: true,
    autoplaySpeed: 5000,
    speed: 750,
    dots: true,
    infinite: true,
    fade: false,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: true}

  // const homeSlider = useRef(null)

  // useEffect(() => {
  //   // eslint-disable-next-line no-undef
  //   $(homeSlider.current).slick({
  //     autoplay: true,
  //     autoplaySpeed: 5000,
  //     speed: 750,
  //     dots: true,
  //     infinite: true,
  //     fade: false,
  //     pauseOnHover: true,
  //     slidesToShow: 1,
  //     slidesToScroll: 1,
  //     arrows: false,
  //     draggable: true,
  //   })
  // }, [])

  return (
    <Slider className="home-slider" {...sliderSettings} >
      {data.allMarketing.nodes.map(marketing => {
        return (
          <div className="slide slick-slide" key={marketing.name}>
            <a href={marketing.buttonUrl ? marketing.buttonUrl.replace("https://www.ssllc.com/", "").replace("https://ssllc.com/", "") : null}>
              <img
                alt={marketing.name}
                className="slide-img"
                src={
                  marketing.backgroundImage
                    ? marketing.backgroundImage
                    : DEFAULT_SLIDE
                }
              />

              {marketing.textBoxVisible ? (
                <div className="the-slide-content no-bg">
                  <a
                    href={marketing.buttonUrl ? marketing.buttonUrl.replace("https://www.ssllc.com/", "").replace("https://ssllc.com/", "") : null}
                    tabIndex="-1"
                  ></a>
                  <div className="row">
                    <a
                      href={marketing.buttonUrl ? marketing.buttonUrl.replace("https://www.ssllc.com/", "").replace("https://ssllc.com/", "") : null}
                      tabIndex="-1"
                    ></a>
                    <div className="small-12 medium-12 large-10 columns end">
                      <a
                        href={marketing.buttonUrl ? marketing.buttonUrl.replace("https://www.ssllc.com/", "").replace("https://ssllc.com/", "") : null}
                        tabIndex="-1"
                      >
                        <h1>{marketing.name}</h1>
                        <p className="slide-text">
                          {marketing.messageBoxDetails}
                        </p>
                      </a>
                      <p>
                        <a
                          href={marketing.buttonUrl ? marketing.buttonUrl.replace("https://www.ssllc.com/", "").replace("https://ssllc.com/", "") : null}
                          tabIndex="-1"
                        ></a>
                        <a
                          href={marketing.buttonUrl ? marketing.buttonUrl.replace("https://www.ssllc.com/", "").replace("https://ssllc.com/", "") : null}
                          className="button secondary tiny caps"
                          tabIndex="-1"
                        >
                          {marketing.buttonText}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
            </a>
          </div>
        )
      })}
    </Slider>
  )
}

export default HomeSlider
