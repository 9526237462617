import React from "react"
import { Link } from "gatsby"

import MANUFACTURER_BUTTON from "../../images/content/browse-manufacturer.jpg"
import INVENTORY_BUTTON from "../../images/content/browse-inventory.jpg"

import OurServices from "./our-services"
import HomeSlider from "./slides"

const Main = () => {
  return (
    <>
      <HomeSlider />
      <br />
      {/* Buttons */}
      <div className="row">
        <div className="medium-9 medium-centered column center">
          
          <h2>
            <div>
              <Link to="/buy-equipment">
                <img alt="Buy Equipment" src={INVENTORY_BUTTON} />
              </Link>
            </div>
            <br />
            <div>
              <Link to="/browse-manufacturers">
                <img alt="Browse Manufacturers" src={MANUFACTURER_BUTTON} />
              </Link>
              <br />
            </div>
            <br />
            <div>We Buy and Sell Used Laboratory and Biotech Equipment.</div>
            <div>"From a Single Piece to an Entire Facility."</div>
          </h2>
        </div>
      </div>

      {/* Body Text */}
      <div className="row">
        <div className="medium-9 medium-centered columns center intro-text">
          <p>
            Surplus Solutions is the world leader in used Lab, Processing and
            Packaging Equipment for the pharmaceutical and biotech Industries.
            As your partner for idle assets we sell the highest quality used lab
            and analytical equipment along with manufacturing, processing, and
            packaging equipment providing one solution for all your equipment
            needs. Search our inventory with thousands for sale from leading
            manufactures including Agilent, Waters, Thermo, GEA, IMA, Bosch, AB
            Sciex, Fitzpatrick or you can browse by all of our manufacturers{" "}
            <Link to="/browse-manufacturers">here</Link>.
            <br />
          </p>
          <p>
            We offer several channels for both buying and selling, and we strive
            to best meet each individual customers’ unique circumstances. As the
            premier auctioneers for the pharma industry we host multiple sales
            every month featuring thousands of lots of late model lab,
            packaging, and processing equipment. Visit our auction page and
            register for any of our upcoming sales{" "}
            <Link to="/auctions">here</Link>.
          </p>
        </div>
      </div>

      <OurServices />
    </>
  )
}

export default Main
