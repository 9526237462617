import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"

import EQUIP_SALES_ICON from "../../images/template/services/equipment-sales.svg"
import APPRA_SERVICES_ICON from "../../images/template/services/appraisal-services.svg"
import LOG_SERVICES_ICON from "../../images/template/services/logistics-services.svg"
import RELOC_SERVICES_ICON from "../../images/template/services/relocation-services.svg"

import BROKERAGE_ICON from "../../images/template/services/brokerage.jpg"
import CONSIGNMENT_ICON from "../../images/template/services/consignment.jpg"
import AUCTION_ICON from "../../images/template/services/auctions.jpg"
import PURCHASE_ICON from "../../images/template/services/purchase.jpg"

const OurServices = () => {
  const tabs = useRef(null)

  useEffect(() => {
    $(tabs.current).easyResponsiveTabs({
      type: "horizontal",
      closed: false,
      tabidentify: "js-tabs-a", // The tab groups identifier
    })
  },[])

  return (
    <>
      <div className="row columns mt10">
        <h4 className="headings">Our Services</h4>
      </div>
      <div className="row  mt8 mb8 home-services-2">
        <div id="js-tabs-horizontal" className="center" ref={tabs}>
          <ul className="resp-tabs-list js-tabs-a resp-htabs">
            <li className="caps center">
              <img
                alt="Equipment Sales"
                id="equipment"
                className="svg tab-icon equipment-icon"
                src={EQUIP_SALES_ICON}
              />
              <span className="tab-text">Equipment Sales</span>
            </li>
            <li className="caps center">
              <img
                alt="Appraisal Services"
                id="appraisal"
                className="svg tab-icon services-icon"
                src={APPRA_SERVICES_ICON}
              />
              <span className="tab-text">Appraisal Services</span>
            </li>
            <li className="caps center">
              <img
                alt="Logistics Services"
                id="logistics"
                className="svg tab-icon logistics-icon"
                src={LOG_SERVICES_ICON}
              />
              <span className="tab-text">Logistics Services</span>
            </li>
            <li className="caps center">
              <img
                alt="Relocation Services"
                id="relocation"
                className="svg tab-icon relocation-icon"
                src={RELOC_SERVICES_ICON}
              />
              <span className="tab-text">Relocation Services</span>
            </li>
          </ul>
          <div className="resp-tabs-container js-tabs-a">
            <div>
              <div className="row">
                <div className="small-12 medium-6 columns mb8">
                  <div className="row">
                    <div className="xsmall-4 columns">
                      {" "}
                      <img
                        src={BROKERAGE_ICON}
                        alt="Brokerage"
                        title="Brokerage"
                        width="300"
                        height="300"
                      />
                    </div>
                    <div className="xsmall-8 columns left-align">
                      <h5 className="caps blue mb0">Brokerage</h5>
                      <p>
                        Our level of understanding, on-the-ground coverage, and
                        attention to detail provides clients with a distinct
                        competitive advantage. Our knowledgeable sales team will
                        ensure consistency and quality&mdash;no matter the
                        location or asset type.
                      </p>
                      <ul>
                        <li>Understand the market trends</li>
                        <li>Relationships and experience</li>
                        <li>Maximize returns</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="small-12 medium-6 columns mb8">
                  <div className="row">
                    <div className="xsmall-4 columns">
                      {" "}
                      <img
                        src={PURCHASE_ICON}
                        alt="Outright Purchase"
                        title="Outright Purchase"
                        width="300"
                        height="300"
                      />
                    </div>
                    <div className="xsmall-8 columns left-align">
                      <h5 className="caps blue mb0">Outright Purchase</h5>
                      <p>
                        Surplus Solutions will buy a single piece of equipment
                        or an entire facility. Please contact our team regarding
                        your surplus equipment.
                      </p>
                      <ul>
                        <li>Immediate Response</li>
                        <li>Hassle free transaction</li>
                        <li>Coordinate logistics to or facility</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="small-12 medium-6 columns mb8">
                  <div className="row">
                    <div className="xsmall-4 columns">
                      {" "}
                      <img
                        src={CONSIGNMENT_ICON}
                        alt="Consignment"
                        title="Consignment"
                        width="300"
                        height="300"
                      />
                    </div>
                    <div className="xsmall-8 columns left-align ">
                      <h5 className="caps blue mb0">Consignment</h5>
                      <p>
                        Our low cost warehouse program allows our customers to
                        free up space until their equipment is sold. Surplus
                        Solutions provides a safe and secure facility while
                        assets go through sales process.
                      </p>
                      <ul>
                        <li>Manage and Track Assets</li>
                        <li>Free up time and space</li>
                        <li>Sell your equipment</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="small-12 medium-6 columns mb8">
                  <div className="row">
                    <div className="xsmall-4 columns">
                      <img alt="Auctions" src={AUCTION_ICON} width="300" height="300" />
                      <br />{" "}
                    </div>
                    <div className="xsmall-8 columns left-align">
                      <h5 className="caps blue mb0">Auctions</h5>
                      <p>
                        Auctions provide our clients with the ideal symmetry
                        between maximum returns and quick turnaround. We work
                        closely with our clients to determine the specific type
                        of auction to best meet their needs.
                      </p>
                      <ul>
                        <li>Onsite, Webcast, Sealed Bids</li>
                        <li>Pre-Aucton planning and consulting</li>
                        <li>Global audience of buyers</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row column center">
                <Link
                  to="sell-equipment"
                  className="button secondary tiny wide"
                >
                  Learn More
                </Link>
              </div>
            </div>

            <div className=" left-align">
              <p> </p>
              <p>
                Whether you need to appraise a single piece of surplus equipment
                or an entire facility, Surplus Solutions’ appraisers can provide
                you with their professional expertise. At Surplus Solutions, we
                are AMEA (Association of Machinery and Equipment Appraisers)
                certified. By requiring AMEA certification, our appraisers
                receive at least 70 hours of continuing education credits every
                five years, and Surplus Solutions must have one ‘USPAP
                compliant’ appraisal reviewed and approved by the AMEA Appraisal
                Review every two years. This ensures we are up to date with the
                latest practices and regulations.
              </p>
              <p> </p>
              <div className="row column center">
                <Link
                  to="our-services/appraisal-services"
                  className="button secondary tiny wide"
                >
                  Learn More
                </Link>
              </div>
            </div>
            <div className=" left-align">
              <p>
                At Surplus Solutions, our services extend far beyond simply the
                brokerage of surplus asset(s). We take care of you from start to
                finish – whether you’re buying or selling. Getting assets bought
                or sold is, quite literally, only half the battle.
              </p>

              <div className="row column center">
                <Link
                  to="our-services/logistic-services"
                  className="button secondary tiny wide"
                >
                  Learn More
                </Link>
              </div>
            </div>
            <div className=" left-align">
              <p>
                Relocating a laboratory or facility can be overwhelming, but
                with the help of Surplus Solutions’ expert planning and
                execution, it can be a seamless transition. Surplus Solutions
                ensures you execute an incident-free move of your precision
                instruments, equipment, and hazardous materials.
              </p>

              <div className="row column center">
                <Link
                  to="/our-services/relocation-services"
                  className="button secondary tiny wide"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OurServices
